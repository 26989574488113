<template>
  <div class="p-5">
    <section
      v-if="getting"
      class="flex justify-center items-center h-[70vh] w-full"
    >
      <spinner class="w-20 h-20" color="oneGreen"></spinner>
    </section>

    <template v-else>
      <section v-if="documents.length > 0">
        <UploadFile
          v-for="(doc, i) in documents"
          :key="i"
          :doc="doc"
          @open="openDoc"
        />
      </section>

      <section
        v-else
        class="min-h-[40vh] flex justify-center items-center w-full"
      >
        <p class="text-center text-base font-bold text-red-500">
          Documents not found for staff, please inform the staff to login to
          his/her account to re-upload documents
        </p>
      </section>

      <section>
        <div
          class="flex flex-col lg:flex-row flex-wrap gap-7 justify-end mt-10 mb-3"
        >
          <button
            v-if="!$store.state.admin.isViewMode"
            @click="completeVerify()"
            :disabled="!completeReady"
            class="bg-oneGreen text-sm py-3 w-full rounded-lg text-white"
          >
            Complete Record Verification
          </button>
        </div>
      </section>
    </template>

    <ActionModal :open="openAction" @close="openAction = false">
      <template #title>
        <p class="underline font-mulish">
          <span class="text-dkgray font-bold uppercase text-base lg:text-xl">
            Showing
          </span>
          <span class="font-medium text-sm lg:text-base ml-2">{{
            doc.documentTypeId?.name
          }}</span>
        </p>
        <p
          v-if="fState.loaded && fState.empty"
          class="text-sm text-red-600 font-bold"
        >
          File not properly uploaded, please tell the staff/pensioner to login
          and re-upload the document
        </p>

        <p v-if="!fState.loaded" class="flex justify-center">
          <spinner class="w-10 h-10" color="oneGreen"></spinner>
        </p>
      </template>

      <template #content>
        <PdfViewer :source="doc.documentUrl" @change-file="fileState" />
      </template>

      <template #action v-if="!$store.state.admin.isViewMode && fState.real">
        <button
          v-if="!doc.approvedBy && !doc.rejectedBy"
          @click="openReject = true"
          class="bg-red-100 text-sm p-3 rounded-lg"
        >
          Reject Document
        </button>

        <button
          v-if="!doc.approvedBy && !doc.rejectedBy"
          @click="updateData('approved')"
          class="bg-oneGreen text-sm p-3 rounded-lg text-white"
        >
          Approve Document
        </button>

        <button
          v-else-if="doc.approvedBy && !doc.authorizedBy"
          @click="updateData('authorized')"
          class="bg-oneGreen text-sm p-3 rounded-lg text-white"
        >
          Authorize Document
        </button>
      </template>
    </ActionModal>

    <ActionModal :open="openReject" @close="openReject = false">
      <template #content>
        <label for="rejectReason">
          Reason for Rejection
          <span class="req">*</span>
        </label>

        <textarea
          id="rejectReason"
          v-model="rejectReason"
          cols="30"
          rows="5"
          class="border border-dashed border-litgray px-4 py-3 rounded-md"
        ></textarea>
        <small v-if="!rejectReady" class="req text-xs font-bold">
          Please enter a valid reason (min 10 characters)
        </small>
      </template>

      <template #action>
        <button
          @click="updateData('rejected')"
          :disabled="!rejectReady"
          class="bg-oneGreen text-sm p-3 rounded-lg text-white"
        >
          Continue
        </button>
      </template>
    </ActionModal>
  </div>
</template>

<script>
import UploadFile from "@/components/admin/records/UploadFile.vue";
import ActionModal from "@/components/admin/records/ActionModal.vue";
import PdfViewer from "@/components/admin/records/PdfViewer.vue";

export default {
  name: "AdminDocumentUpload",
  components: {
    UploadFile,
    ActionModal,
    PdfViewer,
  },
  data() {
    return {
      userId: null,
      getting: false,
      openAction: false,
      openReject: false,
      doc: null,
      documents: [],
      rejectReason: null,
      fState: { empty: false, loaded: false, real: true },
    };
  },
  computed: {
    rejectReady() {
      return this.rejectReason && this.rejectReason.trim().length > 10;
    },
    approvalReady() {
      return this.documents.some((el) => el.approvedBy);
    },
    completeReady() {
      return (
        this.documents.length > 0 &&
        this.documents.every(
          (el) => (el.approvedBy && el.authorizedBy) || el.rejectedBy
        )
      );
    },
  },
  async created() {
    this.$store.commit("staff/updateBStep", 7);
    this.userId = this.$route.params.id;
    await this.getData();
  },
  methods: {
    fileState(d) {
      this.fState = d;
    },
    closeAll() {
      this.openAction = false;
      this.openReject = false;
    },
    next(msg) {
      this.$swal({
        icon: "success",
        text: msg,
      });
      this.$router.push({ name: "All Records" });
    },
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(
          `process/employee/${this.userId}/document`
        );

        if (!res) {
          this.getting = false;
          return;
        }

        const { data } = res.data;

        this.documents = data;

        this.getting = false;
      } catch (error) {
        this.getting = false;
        console.log({ error });
      }
    },
    openDoc(doc) {
      this.doc = doc;
      this.openAction = true;
    },
    async updateData(acn) {
      try {
        const wrd =
          acn === "approved"
            ? "Approve"
            : acn === "authorized"
            ? "Authorize"
            : "Reject";
        const rep = await this.$swal({
          icon: "question",
          text: `Are you sure you want to ${wrd} this document?`,
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        this.$store.commit("general/toggleLoad");
        const payload = { flag: acn };
        if (this.rejectReason) payload.reason = this.rejectReason;

        const res = await this.$http.patch(
          `process/employee/${this.userId}/document/${this.doc._id}`,
          payload
        );

        this.$store.commit("general/toggleLoad");
        if (!res) {
          return;
        }

        // update docs
        const df = res.data.data;
        console.log(df);
        // this.doc = res.data.data;
        this.doc.approvalStatus = df.approvalStatus;
        this.doc.approvedBy = df.approvedBy;
        this.doc.authorizedBy = df.authorizedBy;
        this.doc.rejectedDate = df.rejectedDate;
        this.doc.rejectedBy = df.rejectedBy;
        this.doc.rejectReason = df.rejectReason;
        // this.doc.approvedBy = df.approvedBy;
        this.closeAll();

        this.$swal({
          icon: "success",
          text: `Document ${acn} successfully`,
        });
      } catch (error) {
        console.log({ error });
      }
    },
    async completeVerify() {
      try {
        if (!this.completeReady) {
          this.$swal({
            icon: "error",
            text: "Documents not vaild",
          });
          return;
        }

        this.$store.commit("general/toggleLoad");

        const res = await this.$http.post(
          `process/employee/${this.userId}/complete`,
          {
            baseUrl: `${window.location.origin}/certificate`,
            query: "approve",
          }
        );

        this.$store.commit("general/toggleLoad");
        if (!res) {
          return;
        }

        // update docs
        this.closeAll();
        this.next(res.data.message);
      } catch (error) {
        this.$store.commit("general/toggleLoad");
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>
